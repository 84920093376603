<template>
  <section id="hero" class="flex flex-col lg:flex-row items-center relative">
    <div data-aos="fade" data-aos-delay="200" class="overflow-hidden absolute w-full h-full pointer-events-none">
      <div class="pink-radial absolute"></div>
      <div class="white-radial absolute"></div>
    </div>
    <div class="z-50 flex-1 block 2xl:max-w-7xl xl:max-w-6xl lg:max-w-5xl mx-auto mt-36 lg:mt-72 pb-4 lg:pb-24">
      <div class="flex items-end flex-col lg:flex-row">
        <div data-aos="fade"  class="w-full p-4 lg:p-0 lg:w-1/2">
          <h1 class="text-4xl lg:text-5xl max-w-xl gradient-clip">
            {{$t('pages.home.hero.group-title')}}
            <span>{{$t('pages.home.hero.group-word')}}</span>
            {{$t('pages.home.hero.group-word2')}}
          </h1>
          <p class="max-w-md leading-8 my-12">{{$t('pages.home.hero.help-businesses')}}</p>
          <div class="flex items-center">
            <glow-button :to="localePath('/contact')">
              {{$t('pages.home.hero.get-started')}}
            </glow-button>
            <nuxt-link :to="localePath('/services')" class="ml-4 lg:ml-8 mb-2 arrow-link">{{$t('pages.home.hero.our-services')}}</nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div class="z-10 lg:absolute hero-image" data-aos="fade" data-aos-delay="150" >
      <div class="relative w-full h-full">
        <div class="notifications">
          <div id="not1"
               class="notification rounded-md shadow-2xl flex w-80 items-center absolute top-0 left-16 py-2">
            <div class="w-1/4 p-2">
              <NuxtImg provider="cloudinary" format="webp" width="60" height="60" src="/images/woman1.jpg" loading="lazy" class="rounded-full border-4 border-indigo-50"
                   alt="Woman face icon"/>
            </div>
            <div class="w-3/4 text-gray-600 text-xs p-2">
              <p class="mb-1"><b>{{$t('pages.home.hero.notificationHeader1')}}</b></p>
              <p class="mb-1" v-html="$t('pages.home.hero.notification1')"></p>
              <small class="italic">{{$t('pages.home.hero.timestamp1')}}</small>
            </div>
          </div>
          <div id="not2"
               class="notification rounded-md shadow-2xl flex w-80 items-center absolute top-2 right-4 py-2">
            <div class="w-1/4 p-2">
              <NuxtImg provider="cloudinary" format="webp" width="60" height="60" src="/images/man1.jpg" loading="lazy" class="rounded-full border-4 border-indigo-50" alt="man face icon"/>
            </div>
            <div class="w-3/4 text-gray-600 text-xs p-2">
              <p class="mb-1"><b>{{$t('pages.home.hero.notificationHeader2')}}</b></p>
              <p class="mb-1"  v-html="$t('pages.home.hero.notification2')"></p>
              <small class="italic">{{$t('pages.home.hero.timestamp2')}}</small>
            </div>
          </div>
          <div id="not3"
               class="notification rounded-md shadow-2xl flex w-80 items-center absolute top-28 left-4 py-2">
            <div class="w-1/4 p-2">
              <NuxtImg provider="cloudinary" format="webp" width="60" height="60"  src="/images/woman2.jpg" loading="lazy" class="rounded-full border-4 border-indigo-50"
                   alt="Woman face icon"/>
            </div>
            <div class="w-3/4 text-gray-600 text-xs p-2">
              <p class="mb-1"><b>{{$t('pages.home.hero.notificationHeader3')}}</b></p>
              <p class="mb-1" v-html="$t('pages.home.hero.notification3')"></p>
              <small class="italic">{{$t('pages.home.hero.timestamp3')}}</small>
            </div>
          </div>
          <div class="absolute bottom-24 right-0 py-2">
            <div id="response1"
                 class="response bg-green-600 rounded-md shadow-2xl flex w-72 items-center mb-2 py-2 opacity-90">
              <div class="w-1/8 py-2 px-4">
                <img src="~/assets/images/coursepath.svg" class="" alt="Woman face icon"/>
              </div>
              <div class="w-3/4 text-white">
                <p class="bold text-md">{{$t('pages.home.hero.response1')}}</p>
              </div>
            </div>
            <div id="response2"
                 class="response bg-green-600 rounded-md shadow-2xl flex w-72 items-center mb-2 py-2 opacity-90">
              <div class="w-1/8 py-2 px-4">
                <img src="~/assets/images/invoice.svg" class="" alt="Invoice icon"/>
              </div>
              <div class="w-3/4 text-white">
                <p class="bold text-md">{{$t('pages.home.hero.response2')}}</p>
              </div>
            </div>
          </div>
          <div class="absolute bottom-24 right-0 py-2">
            <div id="response3"
                 class="response bg-green-600 rounded-md shadow-2xl flex w-72 items-center mb-2 py-2 opacity-90">
              <div class="w-1/8 py-2 px-4">
                <img src="~/assets/images/course.svg" class="" alt="Woman face icon"/>
              </div>
              <div class="w-3/4 text-white">
                <p class="bold text-md">{{$t('pages.home.hero.response3')}}</p>
              </div>
            </div>
            <div id="response4"
                 class="response bg-green-600 rounded-md shadow-2xl flex w-72 items-center mb-2 py-2 opacity-90">
              <div class="w-1/8 py-2 px-4">
                <img src="~/assets/images/email2.svg" class="" alt="Woman face icon"/>
              </div>
              <div class="w-3/4 text-white">
                <p class="bold text-md">{{$t('pages.home.hero.response4')}}</p>
              </div>
            </div>
          </div>
          <div class="absolute bottom-24 right-0 py-2">
            <div id="response5"
                 class="response bg-green-600 rounded-md shadow-2xl flex w-72 items-center mb-2 py-2 opacity-90">
              <div class="w-1/8 py-2 px-4">
                <img src="~/assets/images/order.svg" class="" alt="Order icon"/>
              </div>
              <div class="w-3/4 text-white">
                <p class="bold text-md">{{$t('pages.home.hero.response5')}}</p>
              </div>
            </div>
            <div id="response6"
                 class="response bg-green-600 rounded-md shadow-2xl flex w-72 items-center mb-2 py-2 opacity-90">
              <div class="w-1/8 py-2 px-4">
                <img src="~/assets/images/course.svg" class="" alt="Course icon"/>
              </div>
              <div class="w-3/4 text-white">
                <p class="bold text-md">{{$t('pages.home.hero.response6')}}</p>
              </div>
            </div>
          </div>
        </div>
        <NuxtImg
            provider="cloudinary"
            sizes="sm:400px md:700px lg:850px xl:1000px"
            width="1000"
            height="765"
            densities="x1"
            format="webp"
            src="/images/hero.png"
            alt="iMac computer with animations"
            :placeholder="[300, 230, 100, 100]"
            :imgAttrs="{width: 1000, height: 765, alt:'Computer image'}" />
      </div>
    </div>

  </section>
</template>
<script>
import glowButton from "../../blocks/glow-button/glow-button";
import gsap from "gsap";

export default {
  data() {
    return {
      timeline: null
    }
  },
  components: {'glow-button': glowButton},
  mounted() {
    if(window.screen.width >= 1024){
      let ctx = gsap.context(() => {
        gsap.timeline({repeat: -1, repeatDelay: 0.5})
            .to("#not1", {y: 0, opacity: 1, duration: 1, delay: 0.2})
            .to("#response1", {y: 0, opacity: 0.9, duration: 0.2})
            .to("#response2", {y: 0, opacity: 0.9, duration: 0.2})
            .to("#not1, #response1, #response2", {opacity: 0, duration: 0.5, delay: 1.5, scale: 0.95})
            .to("#not2", {y: 0, opacity: 1, duration: 1, delay: 0.2})
            .to("#response3", {y: 0, opacity: 0.9, duration: 0.2})
            .to("#response4", {y: 0, opacity: 0.9, duration: 0.2})
            .to("#not2, #response3, #response4", {opacity: 0, duration: 0.5, delay: 1.5, scale: 0.95})
            .to("#not3", {y: 0, opacity: 1, duration: 1, delay: 0.2})
            .to("#response5", {y: 0, opacity: 0.9, duration: 0.2})
            .to("#response6", {y: 0, opacity: 0.9, duration: 0.2})
            .to("#not3, #response5, #response6", {opacity: 0, duration: 0.5, delay: 1.5, scale: 0.95});
      });
      this.timeline = ctx
    }
  },
  unmounted() {
    if(this.timeline !== null){
      this.timeline.revert()
    }
  }
}
</script>
